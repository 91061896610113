<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <market-links-comp></market-links-comp>
                <div class="sports_bet_list">
                    <div style="text-align: left;margin-left: 5px;height: 50px;line-height: 50px;margin-top: 15px">
                        <input type="text" placeholder="닉네임검색" v-model="search.searchString" style="text-align: center;height: 30px;color: #0c0e0e">
                        <button class="btn03" @click="seachNickName" style="margin-left: 7px;padding: 7px 0;width: 80px">검색</button>
                    </div>
                    <!--배팅내역 리스트-->
                    <market-bet-list-comp
                            :market-list="list"
                            :position="5" @buySuccess="buySuccess"></market-bet-list-comp>
                </div>

                <pagination :page-index="pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"></pagination>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
    </div>
</template>
<style scoped>

</style>
<script>
    import SubTitle from "../../components/SubTitle";
    import Pagination from "../../components/pagenation/Pagination";
    import RightBarComp from "../../components/RightBarComp";
    import sportsConst from "../../common/sportsConst";
    import {getBetList, getBetList2} from "../../network/sportsBetRequest";
    import {fix2, kindText} from "../../common/SportsUtils";
    import SportsBetListComp from "../../components/sports/SportsBetListComp";
    import BoardWriter from "../../components/BoardWriter";
    import LeftBarComp from "../../components/LeftBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import SportsLinksComp from "../../components/links/SportsLinksComp";
    import MarketLinksComp from "../../components/links/MarketeLinksComp";
    import {sellBuyHistory, sellList} from "../../network/sportsMarketRequest";
    import MarketBetListComp from "../../components/sports/MarketBetListComp";

    export default {
        name: "SellList",
        components: {
            MarketBetListComp,
            MarketLinksComp,
            SportsLinksComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp,
            TopbarComp,
            LeftBarComp, BoardWriter, SportsBetListComp, SubTitle, Pagination, RightBarComp
        },
        data() {
            return {
                sportsConst,
                pageNum: 1,
                pageSize: 10,
                total: 1,
                search: {},
                list: []
            }
        },
        methods: {
            seachNickName(){
              this.initList()
            },
            initList() {
                sellList(this.search, this.pageNum, this.pageSize).then(res => {
                    if (res.data.success) {
                        this.list = res.data.data;
                        this.list.forEach((item) => {
                            let betInfo = item.betInfo;
                            betInfo.betInfoGameList.forEach((betInfoGame) => {
                                //배당 소수점 두자리 설정 1.8 => 1.80
                                fix2(betInfoGame)
                                //승무패,핸디오언 텍스트 설정
                                kindText(betInfoGame.game)
                            })
                        })
                    }
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.initList()
            },
            buySuccess() {
                this.initList()
            }
        },
        created() {
            if (!this.$store.state.isLogin) {
                this.$swal({
                    title: '로그인이 필요한 서비스 입니다.',
                    type: 'warning',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                this.$router.push('/login')
                return false;
            }
            this.initList()
        }
    }
</script>
